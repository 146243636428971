import React from "react";
export const Progress = props => {
  return (
    <div
      id="progress"
      className="progress fade"
      style={{
        opacity: props.isLoading ? "1" : "0"
      }}
    >
      <div
        className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
        role="progressbar"
        style={{
          width: props.progress + "%"
        }}
      />
    </div>
  );
};
