import React, { Component } from 'react'
import $ from 'jquery'
import { Parallax } from 'react-parallax'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//my scripts and styles
import { debounce } from './utils/debounce'
import { Progress } from './components/Progress'
import { MediaList } from './components/MediaList'
import './file-uploader/jquery.fileupload.js'

import bg from './img-bg1.jpg'
import InputField from './components/InputField'
import { updateClipboard } from './utils/clipboard'
import { Header } from './components/Header'
import { ModalContainer } from './components/Modal'

const URL = process.env.REACT_APP_FileHandler

class App extends Component {
  state = {
    progress: 0,
    files: [],
    password: '',
    error: null,
    showingDeleteModal: false,
    activeFile: null,
    modalDetails: {
      title: '',
      body: '',
    },
  }

  render() {
    const { activeFile } = this.state
    return (
      <div>
        <Header />
        {/* <div className="background-wrapper">
          <img src={bg} alt="" />
        </div> */}
        <Parallax bgImage={bg} strength={500} className="parallax-component">
          <div className="main">
            {this.renderDropzone()}

            <div className="text-center select-files">
              <button
                className="btn"
                onClick={() => document.getElementById('fileupload').click()}
              >
                Välj filer
              </button>
              <input
                id="fileupload"
                type="file"
                style={{ display: 'none' }}
                name="files[]"
                multiple
                directory="true"
              />
            </div>

            {this.renderPasswordField()}
            {/* Displays error messages */}
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              closeOnClick
              rtl={false}
              hideProgressBar
              pauseOnVisibilityChange
              pauseOnHover
            />
            <ModalContainer
              showing={this.state.showingDeleteModal}
              title={() =>
                activeFile &&
                'Är du säker på att du vill ta bort ' + activeFile.name + '?'
              }
              body={() => {
                if (activeFile) {
                  return (
                    <div>
                      <img
                        src={activeFile.thumbnailUrl && activeFile.url}
                        alt={activeFile.name}
                        className="height-auto"
                      />
                      <span>{activeFile.url}</span>
                    </div>
                  )
                }
                return null
              }}
              toogleModalShow={this.toggleDeleteModal}
              handleSubmit={() => {
                this.handleDelete()
                this.toggleDeleteModal()
              }}
            />
            <Progress
              isLoading={this.state.loading}
              progress={this.state.progress}
            />

            <MediaList
              files={this.state.files}
              handleClick={this.handleClick}
              handleDelete={file => {
                this.setState({
                  activeFile: file,
                  showingDeleteModal: true,
                })
              }}
              handleUnzip={this.handleUnzip}
            />
          </div>
        </Parallax>
      </div>
    )
  }

  //debouncer 250ms
  fetchFiles = debounce(() => {
    $.ajax({
      method: 'GET',
      url: URL + '?&password=' + this.state.password,
    }).done(response => {
      response = JSON.parse(response)
      if (response && response.files) {
        this.setState({ files: response.files })
      }
      if (response.error) {
        //ignore silently
        // console.log('could not fetch')
      }
    })
  }, 250)

  componentDidMount() {
    $(document).bind('drop dragover', function(e) {
      e.preventDefault()
    })

    this.fetchFiles()

    $('#fileupload')
      .fileupload({
        url: URL,
        dataType: 'json',
        dropZone: $('#dropzone'),
        replaceFileInput: false,
        // formData: {
        //   password: this.state.password
        // },
        maxChunkSize: 10000000, // 10 MB
        // beforeSend: function(xhr) {
        //   xhr.setRequestHeader("X-Session-ID", this.state.password);
        // },
        done: (e, data) => {
          if (data.result.error) {
            this.handleError(data.result.error)
          }
          this.fetchFiles()
          // this.setState(prevState => ({
          //   files: [...prevState.files, ...data.result.files],
          //   loading: false
          // }));
        },
        progressall: (e, data) => {
          const progress = parseInt((data.loaded / data.total) * 100, 10)
          this.setState({ progress })
        },
        start: () => {
          this.setState({ loading: true, error: null })
        },
        drop: (e, data) => {
          this.setState({ hovering: false })
        },
        always: (e, data) => {
          this.setState({ loading: false, progress: 0 })
        },
      })
      .on('fileuploadsubmit', (e, data) => {
        data.formData = { password: this.state.password }
      })
  }

  handleError = error => {
    toast.error('' + error)
    this.passswordRef.focus()
    // this.setState({
    //   error
    // });
  }

  handleClick = file => {
    if (updateClipboard(file.url)) {
      toast.warn('Kopierad!')
    } else {
      this.handleError('kunde inte kopiera: ' + file.url)
    }
  }
  handleDelete = () => {
    const file = this.state.activeFile
    $.ajax({
      method: 'DELETE',
      url: file.deleteUrl + '&password=' + this.state.password,
    }).done(response => {
      response = JSON.parse(response)
      if (response.error) {
        this.handleError(response.error)
      } else {
        this.fetchFiles()
      }
      this.setState({ activeFile: null })
    })
  }
  toggleDeleteModal = () => {
    this.setState({
      showingDeleteModal: !this.state.showingDeleteModal,
    })
  }
  handleUnzip = file => {
    $.ajax({
      method: 'GET',
      url:
        URL +
        `?file=${encodeURI(file.name)}&password=${
          this.state.password
        }&unzip=true`,
    }).done(response => {
      response = JSON.parse(response)
      if (response.error) {
        this.handleError(response.error)
      } else {
        this.fetchFiles()
      }
    })
  }

  renderDropzone() {
    return (
      <div className="dropzone-wrapper">
        <div
          id="dropzone"
          onDrop={() => {
            // console.log('drop')
          }}
          onDragLeave={() => {
            this.setState({ hovering: false })
          }}
          onDragEnter={() => {
            this.setState({ hovering: true })
          }}
          className={this.state.hovering ? 'in' : ''}
        >
          Släpp filer här
        </div>
      </div>
    )
  }

  renderPasswordField() {
    if (this.state.files.length) return null

    return (
      <div className="passwordControl">
        <InputField
          innerRef={ref => {
            if (ref) this.passswordRef = ref
          }}
          value={this.state.password}
          onChange={e => {
            const password = e.target.value
            this.setState({ password }, () => {
              if (password.length > 3) {
                this.fetchFiles()
              }
            })
          }}
          placeholder="Lösenord"
        />
      </div>
    )
  }
}

export default App
