import React from 'react'
export const MediaList = ({
  files,
  handleClick,
  handleDelete,
  handleUnzip,
}) => {
  return (
    <div id="files" className="files">
      <h2>Uppladdade filer</h2>
      {files.map((file, index) => (
        <div className="file" key={index}>
          {file.url ? (
            <button
              className="btn btn-link"
              onClick={() => {
                handleClick(file)
              }}
              title={file.name}
              download
            >
              {file.thumbnailUrl ? (
                <img src={file.thumbnailUrl} alt={file.name} />
              ) : (
                <ion-icon name="document" />
              )}
              <span>{file.name}</span>
            </button>
          ) : (
            'Error: ' + file.error
          )}
          {/* {file.name.includes(".zip") && (
            <button
              className="unzip btn btn-link"
              href="#"
              title="Packa upp filen"
              onClick={() => {
                handleUnzip(file);
              }}
            >
              <ion-icon name="folder" />
            </button>
          )} */}
          <button
            className="trash btn btn-link"
            href="#"
            title="Ta bort filen"
            onClick={() => {
              handleDelete(file)
            }}
          >
            <ion-icon name="trash" />
          </button>
        </div>
      ))}
      {files.length === 0 && (
        <p className="text-muted">Inga filer har laddats upp ännu</p>
      )}
    </div>
  )
}
