import React, { Component } from 'react'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export function ModalContainer({
  title,
  body,
  showing,
  toogleModalShow,
  handleSubmit,
  cancelButtonTitle = 'Avbryt',
  submitButtonTitle = 'Ja',
}) {
  return (
    <div>
      <Modal isOpen={showing} toggle={toogleModalShow} centered={true}>
        <ModalHeader toggle={toogleModalShow}>{title()}</ModalHeader>
        <ModalBody>{body()}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {submitButtonTitle}
          </Button>{' '}
          <Button color="secondary" onClick={toogleModalShow}>
            {cancelButtonTitle}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
