import React from 'react'
import logo from '../assets/jmgraphic.svg'
import styled from 'styled-components/macro'
// import { Link } from '@reach/router'

export function Header() {
  return (
    <StyledHeader>
      {/* <Link to="/"> */}
      <img src={logo} className="logo" alt="logo" />
      {/* </Link> */}
      <h1 style={{ pointerEvents: 'none' }}>
        <span>Media</span>
      </h1>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  background-color: #fefefe;
  display: flex;
  height: 100px;
  align-items: center;
  position: relative;

  h1 {
    position: absolute;
    width: 100%;
    text-align: center;

    color: #4f4f4f;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    margin: 0;
    span {
      border-bottom: 2px solid #eca464;
    }
  }

  @media screen and (max-width: 800px) {
    h1 {
      position: initial;
    }
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
    height: auto;
    h1 {
      position: initial;
    }
  }

  .logo {
    width: 300px;
    max-width: 100%;
    height: auto;
    padding: 20px;
  }
`
