async function checkPermission() {
  let result = await navigator.permissions.query({ name: 'clipboard-write' })
  if (result.state === 'granted' || result.state === 'prompt') {
    return true
  }
}
export async function updateClipboard(newClip) {
  if (await checkPermission()) {
    let result = await navigator.clipboard.writeText(newClip)
    return result
  }
  return false
}
