import React, { Component } from 'react'
import styled from 'styled-components/macro'

export default function InputField(props) {
  return <StyledInput type="text" {...props} />
}

const StyledInput = styled.input`
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  height: 50px;
  font-size: 20px;
  font-weight: 200;
  outline: 0;
  width: 100%;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`
